<template>
  <div class="setting world">
      <div class="title backgroundWhite marB10">
      <h1 class="h1">绑定邮箱</h1>
    </div>
    <div class="setting_content backgroundWhite">
      <div class="tip gray">
        <p>注：邮箱用于登录、找回密码。修改成功后，可以使用新邮箱登录。</p>
      </div>
      <div class="content">
        <div class="box">
          <el-form ref="form" :model="form" label-width="200px" class="form">
            <el-form-item label="当前绑定：" class="w350">
              <div class="iphone">{{emails}}</div>
            </el-form-item>
            <el-form-item label="更改绑定邮箱：">
              <el-input placeholder="请输入新邮箱" v-model="form.email" class="w350"></el-input>
            </el-form-item>
            <el-form-item prop="code" label="邮箱验证码：">
              <el-input
                v-model="form.code"
                placeholder="请输入邮箱验证码"
                @keyup.enter.native="loginByphone('form')"
                auto-complete="off"
                class="w350"
              >
                <template slot="append">
                  <el-button
                    @click="sendCheckCode"
                    class="sendcode"
                    :class="{'disabled-style':getCodeBtnDisable}"
                    :disabled="getCodeBtnDisable"
                  >{{codeBtnWord}}</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="marT50">
              <el-button type="primary" class="bs_btn_oo w350" @click="modifyEmail('form')">确认修改</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "settingEmail",
  data() {
    return {
      disabled: false,
      times: 180,
      i: '<i class="el-icon-d-arrow-right"></i>',
      emails: "",
      account: {
        userEmail: "",
      },
      time: "",
      btntxt: "重新发送",
      codeBtnWord: "获取邮箱验证码",
      waitTime: 61,
      form: {
        email: "",
        code: "",
      },
      getCodeBtnDisable:false,
    };
  },
  computed: {
    // 用于校验邮箱格式格式是否正确
    emailNumberStyle() {
      let reg = /^[0-9a-zA-Z]+([\.\-_]*[0-9a-zA-Z]+)*@([0-9a-zA-Z]+[\-_]*[0-9a-zA-Z]+\.)+[0-9a-zA-Z]{2,6}$/;
      if (!reg.test(this.form.email)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    // 查询当前邮箱
    getEmailInfo() {
      let that = this;
      that.$api.getEmail().then((res) => {
        console.log("获取邮箱", res.data.data);
        if (!res.data.success) {
          that.emails = "暂未绑定";
        } else {
          if (res.data.msg == null || res.data.msg == "") {
            that.emails = "暂未绑定";
          } else {
            that.emails = res.data.msg;
            console.log("www", that.form.emails);
          }
        }
      });
    },
    // 发送验证码
    sendCheckCode() {
      let email = this.form.email;
      console.log(this.form.email);
      if (!this.form.email) {
        this.$message.error("请输入正确的邮箱");
        return;
      } else {
        this.$api.sendModifyEmailCode({ email: email }).then((res) => {
          console.log("邮箱", res);
          if (res.data) {
            console.log(res, "发送邮箱");
            this.$message.success({
              message: "验证码已经发送至您的邮箱，请注意接收",
            });
          } else {
            this.$message.error({
              message: "网络错误",
            });
          }
        });
        let that = this;
        that.waitTime--;
        that.getCodeBtnDisable = true;
        this.codeBtnWord = `${this.waitTime}s 后重新获取`;
        let timer = setInterval(function () {
          if (that.waitTime > 1) {
            that.waitTime--;
            that.codeBtnWord = `${that.waitTime}s 后重新获取`;
          } else {
            clearInterval(timer);
            that.codeBtnWord = "获取验证码";
            that.getCodeBtnDisable = false;
            that.waitTime = 181;
          }
        }, 1000);
      }
    },
    //确认修改邮箱
    modifyEmail() {
      if (!this.form.email) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.form.code) {
        this.$message.error("请输入收到的验证码");
        return;
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let params = {
            email: this.form.email,
            code: this.form.code,
          };
          let res = await this.$api.modifyEmail(params);
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.form={}
            this.getEmailInfo();

          } else this.$message.error(res.data.msg);
          console.log("修改手机号", res);
        }
      });
    },
  },
  created() {
    this.getEmailInfo();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/setting.less";
/deep/ .el-input-group__append {
  background: #ffffff;
  color: #00924c;
  // border: 1px solid #00924c;
}
.world {
  background: #ffffff;
  margin: 0px;
  padding: 30px 20px !important;

}
</style>
